import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { AuthContext } from "../AuthContext";
import Logo from "../assets/images/logo.png";
import MainMenu from "./MainMenu.js";
import Notify from "./Notify";
import HistoryMessage from "./PageComponent/HistoryMessage.js";
import GetMessage from "../requests/GetMessage.js";
import GameOrder from "./GameOrder";
import GameHistory from "./GameHistory";
import "../css/Game.css";
import { getTodayRange } from "../utils/TimeRange";
import Csrae from "./Csrae.js";

const Game = () => {
  const { useAuth, authFetch, userInfo } = useContext(AuthContext);
  const [logged] = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const roomType = params.get("type");
  const roomId = params.get("room");

  const [first, setFirst] = useState(1);
  const [second, setSecond] = useState(1);
  const [third, setThird] = useState(1);
  const [result, setResult] = useState(first + second + third);
  const [roundHistory, setRoundHistory] = useState([]);
  const [currentRoundId, setCurrentRoundId] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentMessageId, setCurrentMessageId] = useState(-1);
  const [userMessage, setUserMessage] = useState("");
  const [orderMode, setOrderMode] = useState(false);
  const [historyMode, setHistoryMode] = useState(false);
  const [notifyMessage, setNotifyMessage] = React.useState("");
  const [onlineNumber, setOnlineNumber] = useState(58906);
  const [messageData, setMessageData] = useState([]);
  const { data: dataGetMessage, refetch } = GetMessage({
    messageId: currentMessageId,
  });
  useEffect(() => {
    const {
      data,
      previous_round,
      current_round,
      message_id: messageId,
    } = dataGetMessage?.data || {};
    const rTime =
      270 -
      Math.round(
        (new Date().getTime() - new Date(current_round?.start_time).getTime()) /
          1000
      );
    if (rTime > remainingTime) {
      setRemainingTime(rTime);
    }
    setMessageData((oldData) => {
      return (data || []).concat(oldData);
    });
    setFirst(previous_round?.first);
    setSecond(previous_round?.second);
    setThird(previous_round?.third);
    setResult(
      previous_round?.first + previous_round?.second + previous_round?.third
    );
    if (previous_round?.id && previous_round.id !== currentRoundId)
      setCurrentRoundId(previous_round.id);
    if (messageId) setCurrentMessageId(messageId);
  }, [dataGetMessage]);

  document
    .getElementsByName("viewport")[0]
    .setAttribute("content", "width=device-width, initial-scale=0.45");
  document
    .getElementsByTagName("body")[0]
    .setAttribute("style", "background:#359361");

  useEffect(() => {
    if (!logged) {
      window.location.href = "/login";
      return;
    } else {
      const url = `/api/round/history?number_round=10`;
      authFetch(url).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setRoundHistory(
              data?.data?.map(
                (round) => round?.first + round?.second + round?.third
              )
            );
          });
        }
      });
    }
  }, [logged, authFetch]);
  useEffect(() => {
    if (!roomType) window.location.href = "/room?type=normal";
  }, [roomType]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (notifyMessage) setNotifyMessage("");
    }, 5_000);
    return () => clearTimeout(timer);
  }, [notifyMessage]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOnlineNumber(onlineNumber + Math.floor(Math.random() * 31) - 10);
    }, 1_000);
    return () => clearTimeout(timer);
  }, [onlineNumber]);
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 3000);

    return () => clearInterval(interval);
  }, [refetch]);

  const [pendingOrderRows, setPendingOrderRows] = useState([]);
  const [todayOrderRows, setTodayOrderRows] = useState([]);
  useEffect(() => {
    if (!orderMode) return;
    const { start, end } = getTodayRange();
    const baseUrl = "/api/user/orders";
    authFetch(`${baseUrl}?start=${start}&end=${end}&request_type=2`).then(
      (response) => {
        response.json().then((data) => {
          setPendingOrderRows(data?.data);
        });
      }
    );

    authFetch(`${baseUrl}?start=${start}&end=${end}&request_type=3`).then(
      (response) => {
        response.json().then((data) => {
          setTodayOrderRows(data?.data);
        });
      }
    );
  }, [orderMode]);

  const [historyRows, setHistoryRows] = useState([]);
  useEffect(() => {
    if (!historyMode) return;
    const url = `/api/round/history?number_round=100`;
    authFetch(url).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setHistoryRows(
            data?.data?.map((row) => {
              const result = row.first + row.second + row.third;
              return {
                "1-time": <p style={{ color: "#8E695D" }}>{row.start_time}</p>,
                "2-id": <p style={{ color: "#8E695D" }}>{row.id}</p>,
                "3-code": (
                  <>
                    <b style={{ color: "blue" }}>{row.first} </b> +
                    <b style={{ color: "blue" }}> {row.second} </b> +
                    <b style={{ color: "blue" }}> {row.third}</b>
                  </>
                ),
                "4-result": <b style={{ color: "red" }}>{result}</b>,
                "5-res1": (
                  <b style={{ color: "blue" }}>{result > 15 ? "B" : "S"}</b>
                ),
                "6-res2": (
                  <b style={{ color: "blue" }}>
                    {result % 2 === 0 ? "E" : "O"}
                  </b>
                ),
              };
            })
          );
        });
      }
    });
  }, [historyMode]);

  const handleUserMessageChange = (e) => {
    setUserMessage(e.target.value);
  };
  const handleRefreshClick = () => {
    window.location.reload();
  };
  const handleOrderLogClick = () => {
    setOrderMode((prevState) => !prevState);
    setHistoryMode(false);
  };
  const handleHistoryClick = () => {
    setHistoryMode((prevState) => !prevState);
    setOrderMode(false);
  };
  const handleSendOrderClick = () => {
    let errorMessage = "";
    const pattern = /^[OE]\/\d+$/;
    if (!userMessage || !pattern.test(userMessage)) {
      errorMessage = "Không đúng cú pháp!";
    } else {
      const value = parseInt(userMessage.split("/")[1]);
      errorMessage =
        value < 100_000
          ? "Số tiền tối thiểu của một lệnh: 100000.00"
          : value > userInfo?.fund
          ? "Tài khoản của bạn không đủ tiền!"
          : "";
    }

    if (errorMessage) {
      setNotifyMessage(errorMessage);
      return false;
    }

    const opts = {
      message: userMessage,
      current_message_id: currentMessageId,
    };
    authFetch("/api/order", {
      method: "post",
      body: JSON.stringify(opts),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseData) => {
          const {
            data,
            previous_round,
            current_round,
            message_id: messageId,
          } = responseData || {};
          const rTime =
            270 -
            Math.round(
              (new Date().getTime() -
                new Date(current_round?.start_time).getTime()) /
                1000
            );
          if (rTime > remainingTime) {
            setRemainingTime(rTime);
          }
          setMessageData((oldData) => {
            return (data || []).concat(oldData);
          });
          setFirst(previous_round?.first);
          setSecond(previous_round?.second);
          setThird(previous_round?.third);
          setResult(
            previous_round?.first +
              previous_round?.second +
              previous_round?.third
          );
          if (previous_round?.id !== currentRoundId)
            setCurrentRoundId(previous_round.id);
          if (messageId) setCurrentMessageId(messageId);
        });
        setUserMessage("");
        setNotifyMessage("Đặt hàng thành công!");
      } else {
        setNotifyMessage(
          "Không thể gửi yêu cầu đặt hàng, vui lòng đợi thử lại!"
        );
      }
    });
  };

  useEffect(() => {
    if (remainingTime <= 0) {
      const timer = setInterval(() => {
        const ranFirst = Math.floor(Math.random() * 9) + 1;
        const ranSecond = Math.floor(Math.random() * 9) + 1;
        const ranThird = Math.floor(Math.random() * 9) + 1;
        setFirst(ranFirst);
        setSecond(ranSecond);
        setThird(ranThird);
        setResult(ranFirst + ranSecond + ranThird);
      }, 200);
      return () => clearInterval(timer);
    } else {
      const timer = setInterval(() => {
        setRemainingTime((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [remainingTime]);

  return (
    userInfo && (
      <Box
        margin={0}
        padding={0}
        fontSize={24}
        fontFamily='"Microsoft YaHei", "Arial", "sans-serif"'
        className="game"
        display="flex"
      >
        {notifyMessage && (
          <Notify
            message={
              <p style={{ display: "flex", margin: "auto" }}>{notifyMessage}</p>
            }
          />
        )}
        <Box
          sx={{ cursor: "pointer" }}
          display="grid"
          maxHeight="40vh"
          className="code-menu"
        >
          <img src={Logo} alt="logo" />
          <img
            src={require(`../assets/images/avatar/${
              userInfo?.avatar || 0
            }.png`)}
            alt="user"
          />
          <Button variant="contained" onClick={handleRefreshClick}>
            Làm mới
          </Button>
          <Button variant="contained" onClick={handleOrderLogClick}>
            Ghi lại
          </Button>
          <Button variant="contained" onClick={handleHistoryClick}>
            Lịch sử
          </Button>
        </Box>
        {orderMode && (
          <GameOrder
            pendingOrderRows={pendingOrderRows}
            todayOrderRows={todayOrderRows}
          />
        )}
        {historyMode && <GameHistory historyRows={historyRows} />}
        {!orderMode && !historyMode && (
          <Grid container width="100%" display="flow-root" overflow="auto">
            <Grid item md={12} height="fit-content">
              <Box className="codes">
                <Box className="code fl">
                  <Grid container style={{ padding: 16 }}>
                    <Grid item xs={6}>
                      <p style={{ fontSize: "1.6rem" }}>
                        Phòng{" "}
                        {roomType === "normal" ? "bình thường" : "cao thủ VIP"}{" "}
                        VIP
                        {roomId}
                      </p>
                      <br />
                      <b style={{ fontSize: 20, color: "#f40" }}>
                        {currentRoundId}
                      </b>{" "}
                      Kết quả giao dịch
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      <p style={{ fontSize: 20 }}>
                        Số <b style={{ color: "#f40" }}>{currentRoundId + 1}</b>
                      </p>
                      <br />
                      Dừng cược{" "}
                      <b style={{ fontSize: 20, color: "#f40" }}>
                        {remainingTime}
                      </b>{" "}
                      thứ hai
                    </Grid>
                  </Grid>
                  <Box display="grid" style={{ paddingLeft: 16 }}>
                    <Box display="flex" alignItems="center">
                      <span className="num-code">{first}</span>{" "}
                      <span className="num-add">+</span>{" "}
                      <span className="num-code">{second}</span>{" "}
                      <span className="num-add">+</span>{" "}
                      <span className="num-code">{third}</span>{" "}
                      <span className="num-add">=</span>{" "}
                      <span className="num-result">{result}</span>{" "}
                      <span className="num-code">
                        {result > 15 ? "B" : "S"}
                      </span>{" "}
                      <span className="num-code">
                        {result % 2 === 0 ? "E" : "O"}
                      </span>{" "}
                    </Box>
                    <Box display="flex" pt={1} alignItems="center">
                      {roundHistory.map((value) => (
                        <span className="history-code">{value}</span>
                      ))}
                      <span style={{ fontSize: 18 }}>Hồ sơ mười kết quả</span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} height="fit-content">
              <Box className="user-message" alignItems="center" width="100%">
                <input
                  placeholder="Mã giao dịch/số tiền"
                  type="text"
                  value={userMessage}
                  style={{ width: "50%" }}
                  onChange={handleUserMessageChange}
                ></input>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 20,
                    paddingX: 3,
                    marginX: 1,
                    border: "1px solid white",
                    maxWidth: "15%",
                  }}
                  onClick={handleSendOrderClick}
                >
                  Gửi
                </Button>
              </Box>
            </Grid>
            <Box color="white" ml={1} mt={2} fontSize="2rem">
              Số tiền hiện tại:{" "}
              <b style={{ color: "#F5D68B" }}>{userInfo?.fund}</b>
              &nbsp;&nbsp;&nbsp;&nbsp; Trực tuyến:{" "}
              <b style={{ color: "#F5D68B" }}>{onlineNumber}</b>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              overflow="auto"
              height="100%"
            >
              <HistoryMessage messageData={messageData} />
            </Box>
          </Grid>
        )}
        <MainMenu maxWidth="100%" />
      </Box>
    )
  );
};

export default Game;
