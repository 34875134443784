/* eslint-disable import/no-cycle */
import axios from "axios";
// import * as apiLogin from './auth'

/**
 * Get token from state
 * @returns {string|*}
 */
export function getToken(tokenType) {
  try {
    const state = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY"))[
      "access_token"
    ];
    return state;
  } catch (e) {
    return "none";
  }
}

/**
 *
 * @type {{headers: {"Content-Type": string}}}
 */
const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${getToken("REACT_TOKEN_AUTH_KEY")}`,
    "ngrok-skip-browser-warning": true,
  },
  timeout: 200000,
};
/**
 *
 * @type {Object}
 */
const axiosAPI = axios.create(defaultOptions);

axiosAPI.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY"))[
    "access_token"
  ];
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export default axiosAPI;
