import React, { useContext, useState } from "react";
import { Box, Button } from "@mui/material";
import { AuthContext } from "../AuthContext";
import { ALPHANUMERIC_PATTERN } from "../constants";
import UserInfo from "./UserInfo";
import Csrae from "./Csrae";
import "../css/User.css";

const User = () => {
  const { authFetch, reLogin, userInfo } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmitClick = (e) => {
    e.preventDefault();
    let errorMessage = "";
    if (password.length < 3 || password.length > 16) {
      errorMessage = "Tạo mật khẩu chứa ít nhất 3-16 ký tự";
    } else if (!ALPHANUMERIC_PATTERN.test(password)) {
      errorMessage = "Mật khẩu có thể chứa chữ số tiếng Anh";
    } else if (password !== confirmPassword) {
      errorMessage = "Hai mật khẩu không giống nhau";
    }

    if (errorMessage) {
      alert(errorMessage);
      return;
    }
    const opts = {
      new_password: password,
    };
    authFetch("/api/user/change-password", {
      method: "put",
      body: JSON.stringify(opts),
    }).then((response) => {
      if (response.status === 200) {
        alert("Đổi mật khẩu thành công, cần đăng nhập lại!");
      } else {
        alert("Đổi mật khẩu thất bại!");
      }
      reLogin();
    });
  };

  const handleBackClick = () => {
    window.location.href = "/user";
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    userInfo && (
      <Box className="user" height={`${wHeight}px`}>
        <Csrae />
        <Box height="90%" display="flex" flexDirection="column">
          <UserInfo />
          <form action="#" autoComplete={false} style={{ margin: 16 }}>
            <div className="account-div-input">
              <label>MẬT KHẨU</label>
              <input
                type="password"
                onChange={handlePasswordChange}
                value={password}
                className="account-input"
              />
            </div>
            <div className="account-div-input">
              <label>XÁC NHẬN MẬT KHẨU</label>
              <input
                type="password"
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                className="account-input"
              />
            </div>
            <Button
              variant="contained"
              size="medium"
              className="account-btn"
              onClick={onSubmitClick}
              type="submit"
              color="primary"
            >
              Gửi đi
            </Button>
          </form>
        </Box>
        <Box className="btn" onClick={handleBackClick}>
          trở lại trang chủ cá nhân
        </Box>
      </Box>
    )
  );
};

export default User;
