import React from "react";
import { Box } from "@mui/material";
import "../css/Game.css";
import SimpleTable from "./SimpleTable";

const columns = [
  { field: "gameId", label: "Số kỳ", width: "15%" },
  { field: "orderContent", label: "Nội dung", width: "25%" },
  { field: "value", label: "Số tiền", width: "20%" },
  { field: "time", label: "Thời gian phục vụ", width: "25%" },
  { field: "status", label: "Kết quả", width: "15%" },
];

const GameOrder = ({ pendingOrderRows, todayOrderRows }) => (
  <Box width="80%" height="100vh" bgcolor="#AEBDDE">
    <Box bgcolor="#CFE9F6" color="#3B7796" textAlign="center" padding={1}>
      Các đơn đặt hàng chưa được giải quyết
    </Box>
    <SimpleTable
      columns={columns}
      rows={pendingOrderRows}
      emptyText="Không có đơn đặt hàng chưa thanh toán"
      bgcolor="white"
      style={{ height: "30%", overflow: "auto" }}
    />
    <Box
      bgcolor="#DEFFD0"
      color="#3C763D"
      textAlign="center"
      padding={1}
      marginTop={3}
    >
      Đặt hàng hôm nay
    </Box>
    <SimpleTable
      columns={columns}
      rows={todayOrderRows}
      emptyText="Không có đơn đặt hàng"
      bgcolor="white"
      style={{ height: "50%", overflow: "auto" }}
    />
  </Box>
);

export default GameOrder;
