import React, { useState } from "react";
import { Button } from "@mui/material";
import { login } from "../auth";
import { ALPHANUMERIC_PATTERN } from "../constants.js";

const SignupForm = ({ reference, setReference }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [avatar, setAvatar] = useState(0);

  const onSubmitClick = (e) => {
    e.preventDefault();
    let errorMessage = "";
    if (username.length < 3 || username.length > 16) {
      errorMessage = "Tạo tên người dùng ít nhất 3 - 16 ký tự";
    } else if (!ALPHANUMERIC_PATTERN.test(username)) {
      errorMessage = "Tên người dùng có thể chứa số tiếng Anh";
    } else if (password.length < 3 || password.length > 16) {
      errorMessage = "Tạo mật khẩu chứa ít nhất 3-16 ký tự";
    } else if (password !== confirmPassword) {
      errorMessage = "Hai mật khẩu không giống nhau";
    } else if (!reference) {
      errorMessage = "Mã đăng ký không được để trống";
    } else if (isNaN(reference)) {
      errorMessage = "Mã đăng ký chỉ có thể là một số";
    }

    if (errorMessage) {
      alert(errorMessage);
      return;
    }
    const opts = {
      username: username,
      password: password,
      ref: reference,
      avatar: avatar.toString(),
    };
    fetch("/api/register", {
      method: "post",
      body: JSON.stringify(opts),
    }).then((r) => {
      r.json().then((data) => {
        if (data.access_token) {
          login(data);
          window.location.href = "/home";
        } else {
          alert(data.message);
        }
      });
    });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRefChange = (e) => {
    setReference(e.target.value);
    // setReference("1");
  };

  return (
    <div>
      <form action="#" autoComplete={false}>
        <div className="Login-div-input">
          <label className="Login-label">TÊN TÀI KHOẢN</label>
          <input
            type="text"
            onChange={handleUsernameChange}
            value={username}
            className="Login-input"
          />
        </div>
        <div className="Login-div-input">
          <label className="Login-label">MẬT KHẨU</label>
          <input
            type="password"
            onChange={handlePasswordChange}
            value={password}
            className="Login-input"
          />
        </div>
        <div className="Login-div-input">
          <label className="Login-label">XÁC NHẬN MẬT KHẨU</label>
          <input
            type="password"
            onChange={handleConfirmPasswordChange}
            value={confirmPassword}
            className="Login-input"
          />
        </div>
        <div className="Login-div-input">
          <label className="Login-label">MÃ SỐ ĐĂNG KÝ</label>
          <input
            type="text"
            onChange={handleRefChange}
            value={reference}
            className="Login-input"
          />
        </div>
        <div className="Login-div-input" style={{ display: "flex" }}>
          <img
            src={require(`../assets/images/avatar/${avatar}.png`)}
            style={{ width: 64 }}
            alt=""
          />
          <Button
            variant="contained"
            onClick={() =>
              setAvatar((prevState) => (prevState < 23 ? prevState + 1 : 0))
            }
            style={{ width: 100, fontSize: 12, marginLeft: 4 }}
          >
            Bấm để thay thế
          </Button>
        </div>
        <Button
          variant="contained"
          size="medium"
          className="Login-btn"
          onClick={onSubmitClick}
          type="submit"
        >
          Đăng ký
        </Button>
      </form>
    </div>
  );
};

export default SignupForm;
