import React from "react";
import { Box, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";

const PreviewImage = ({ imageUrl, handleSend, handleRemove }) => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      bgcolor="rgba(0,0,0,0.3)"
      display="flex"
      zIndex={9999999}
    >
      <Box
        position="absolute"
        width="90%"
        height="50%"
        top="30%"
        left="calc(5% - 4px)"
        bgcolor="aliceblue"
        border="2px solid aliceblue"
      >
        {imageUrl && (
          <img
            src={imageUrl}
            width="100%"
            height="90%"
            overflow="hidden"
            position="relative"
            alt="preview"
          />
        )}
        <IconButton
          color="primary"
          aria-label="send message"
          fontSize="large"
          onClick={handleSend}
          style={{ width: "10%", marginLeft: "35%" }}
        >
          <SendIcon style={{ width: "100%", height: "10%" }} />
        </IconButton>
        <IconButton
          aria-label="send message"
          fontSize="large"
          onClick={handleRemove}
          style={{ width: "10%", marginLeft: "10%" }}
        >
          <DeleteIcon style={{ width: "100%", height: "10%" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PreviewImage;
