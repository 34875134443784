import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Divider,
  Grid,
  Typography,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import SendIcon from "@mui/icons-material/Send";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import GetTicketMessage from "../requests/GetTicketMessage.js";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../AuthContext";
import UserInfo from "./UserInfo";
import TicketMessage from "./PageComponent/TicketMessage.js";
import PreviewImage from "./PageComponent/PreviewImage.js";
import Csrae from "./Csrae.js";
import ConfirmCloseTicketDialog from "./PageComponent/ConfirmCloseTicketDialog.js";
import CustomerServiceIMG from "../assets/images/avatar/CustomerService.png";
import "../css/User.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const GridHeader = styled(Grid)({
  padding: "12px 0px",
  backgroundColor: "white",
  font: "400 .8em 'Open Sans', sans-serif",
});

const CustomerService = React.memo(() => {
  const { useAuth, authFetch, userInfo, setUnSeenMsg } =
    useContext(AuthContext);
  const [logged] = useAuth();
  const [inChat, setInChat] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImg, setPreviewImg] = useState(false);
  const [ticket, setTicket] = useState(-1);
  const [messageData, setMessageData] = useState([]);
  const [lastMsg, setLastMsg] = useState(-1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (!logged) window.location.href = "/";
  }, [logged]);
  const { data: dataGetMessage, error } = GetTicketMessage({
    messageId: lastMsg,
    enabled: inChat,
  });
  useEffect(() => {
    if (error) {
      setInChat(false);
      alert("Yêu cầu đã bị đóng!");
    }
    if (
      dataGetMessage &&
      dataGetMessage.status === 200 &&
      dataGetMessage.data.data.length > 0
    ) {
      const newData = dataGetMessage.data;
      setMessageData((p) => p.concat(newData.data));
      setLastMsg(newData.last_message);
      if (ticket === -1) setTicket(newData.ticket);
      authFetch(
        `/api/ticket/update_last_seen_message_id/${newData.last_message}`,
        {
          method: "post",
        }
      ).then((response) => {
        if (response.status === 200) {
          setUnSeenMsg(false);
        }
      });
    }
  }, [dataGetMessage, error, authFetch, setUnSeenMsg, ticket]);
  const handleOpenCurrentTicketClick = () => {
    setInChat(true);
  };
  const handleNewTicketClick = () => {
    authFetch("/api/ticket/open_ticket", {
      method: "post",
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setTicket(data?.ticket);
          setInChat(true);
        });
      } else {
        setInChat(false);
        alert("Không thể mở yêu cầu mới!");
      }
    });
  };
  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
    handleCloseMenu();
  };
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };
  const handleCloseTicketClick = () => {
    authFetch(`/api/ticket/close_ticket/${ticket}`, {
      method: "post",
    }).then((response) => {
      if (response.status === 202) {
        setMessageData([]);
        setTicket(-1);
        handleRemoveImage();
        setInChat(false);
        handleConfirmDialogClose();
      } else {
        alert("Không thể đóng yêu cầu!");
      }
    });
  };
  const handleSendMessageClick = () => {
    const opts = {
      message: userMessage,
    };
    authFetch("/api/ticket/send_message", {
      method: "post",
      body: JSON.stringify(opts),
    }).then((response) => {
      if (response.status === 200) {
        setUserMessage("");
        scrollToBottom();
      } else {
        alert("Không thể mở yêu cầu mới!");
      }
    });
  };
  const handleUserMessageChange = (e) => {
    setUserMessage(e.target.value);
  };
  const handleBackClick = () => {
    window.location.href = "/home";
  };
  const onKeyPress = (e) => {
    if (e.key === "Enter" && userMessage && userMessage !== "") {
      handleSendMessageClick();
      e.preventDefault();
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.size <= 5 * 1024 * 1024 &&
      file.type.startsWith("image/")
    ) {
      setSelectedFile(file);
      setPreviewImg(true);
    } else {
      setSelectedFile(null);
      alert("Please select an image file smaller or equal to 5MB.");
    }
  };
  const handleUploadImage = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      authFetch("/api/ticket/upload_image", {
        method: "post",
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          setSelectedFile(null);
        } else {
          alert("Không thể gửi ảnh!");
        }
      });
    } else {
      alert("Please select a file.");
    }
  };
  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewImg(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    userInfo && (
      <>
        <Box className="user" height={`${wHeight}px`}>
          <ConfirmCloseTicketDialog
            open={openConfirmDialog}
            onClose={handleConfirmDialogClose}
            onSubmit={handleCloseTicketClick}
          />
          {!inChat && <Csrae />}
          {previewImg && selectedFile && (
            <PreviewImage
              imageUrl={URL.createObjectURL(selectedFile)}
              handleSend={handleUploadImage}
              handleRemove={handleRemoveImage}
            />
          )}
          <Box height="90%" display="flex" flexDirection="column">
            <UserInfo />
            {inChat && (
              <>
                <GridHeader container>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    sx={{ justifyContent: "center" }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="back"
                      onClick={() => setInChat(false)}
                    >
                      <KeyboardArrowLeft fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    sx={{ justifyContent: "space-evenly" }}
                  >
                    <Avatar
                      style={{ width: 30, height: 30 }}
                      variant="rounded"
                      alt="cs"
                      src={CustomerServiceIMG}
                    />
                    <Typography fontSize="larger" fontWeight="bold">
                      CUSTOMER SERVICE
                    </Typography>
                  </Grid>
                  <Grid item xs={2} display="flex" justifyContent="center">
                    <IconButton
                      aria-label="close ticket"
                      onClick={handleOpenMenu}
                    >
                      <MenuIcon fontSize="large" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        sx={{ color: "red" }}
                        onClick={handleConfirmDialogOpen}
                      >
                        Đóng yêu cầu
                      </MenuItem>
                    </Menu>
                  </Grid>
                </GridHeader>
                <Divider />
              </>
            )}
            {inChat ? (
              <Box
                display="flex"
                flexDirection="column"
                overflow="auto"
                mt={2}
                mx={1}
              >
                <TicketMessage
                  messageData={messageData}
                  scrollToBottom={scrollToBottom}
                  messagesEndRef={messagesEndRef}
                  userInfo={userInfo}
                />
              </Box>
            ) : (
              <>
                <Button
                  className="entry-btn"
                  startIcon={<ConfirmationNumberIcon />}
                  onClick={handleOpenCurrentTicketClick}
                >
                  Yêu cầu đang mở
                </Button>
                <Button
                  className="entry-btn"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleNewTicketClick}
                >
                  Tạo yêu cầu mới
                </Button>
              </>
            )}
          </Box>
          {inChat ? (
            <>
              <Divider />
              <Box
                className="user-message"
                alignItems="center"
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                py={2}
              >
                <IconButton
                  color="primary"
                  aria-label="add image"
                  component="label"
                  role={undefined}
                >
                  <AddPhotoAlternateIcon />
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    key={selectedFile}
                  />
                </IconButton>
                <TextField
                  variant="outlined"
                  value={userMessage}
                  style={{ width: "80%", background: "azure" }}
                  onChange={handleUserMessageChange}
                  onKeyPress={onKeyPress}
                  disabled={selectedFile}
                  size="small"
                  multiline
                />
                <IconButton
                  color="primary"
                  aria-label="send message"
                  onClick={handleSendMessageClick}
                  disabled={!userMessage || userMessage === ""}
                  style={{ width: "10%" }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <Box className="btn" onClick={handleBackClick}>
              trở lại trang chủ
            </Box>
          )}
          {/* </>
          )} */}
        </Box>
      </>
    )
  );
});

export default CustomerService;
