import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { CHAT_URL } from "../constants";
import UserInfo from "./UserInfo";
import { AuthContext } from "../AuthContext";
import { ALPHABET_PATTERN, BANK_ACC_NAME_PATTERN } from "../constants.js";
import Csrae from "./Csrae.js";
import "../css/User.css";

const Cash = () => {
  const { useAuth, authFetch, userInfo } = useContext(AuthContext);
  const [logged] = useAuth();
  const [bankName, setBankName] = useState("");
  const [bankAccNo, setBankAccNo] = useState("");
  const [bankAccName, setBankAccName] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (!logged) {
      window.location.href = "/login";
    }
  }, [logged]);

  const handleBankNameChange = (e) => {
    setBankName(e.target.value);
  };
  const handleBankAccNoChange = (e) => {
    setBankAccNo(e.target.value);
  };
  const handleBankAccNameChange = (e) => {
    setBankAccName(e.target.value);
  };
  const handleValueChange = (e) => {
    setValue(e.target.value);
  };
  const handlePaymentClick = (e) => {
    let errorMessage = "";
    if (!bankName) {
      errorMessage = "Tên ngân hàng không được để trống";
    } else if (!ALPHABET_PATTERN.test(bankName)) {
      errorMessage = "Tên ngân hàng không hợp lệ";
    } else if (!bankAccNo) {
      errorMessage = "Số tài khoản ngân hàng không được để trống";
    } else if (isNaN(bankAccNo)) {
      errorMessage = "Số tài khoản ngân hàng không hợp lệ";
    } else if (!bankAccName) {
      errorMessage = "Họ và tên không được để trống";
    } else if (!BANK_ACC_NAME_PATTERN.test(bankAccName)) {
      errorMessage = "Họ và tên không hợp lệ";
    } else if (!value || isNaN(value)) {
      errorMessage = "Số tiền không hợp lệ";
    } else if (value < 2_000_000 || value > 1_000_000_000) {
      errorMessage =
        "Số tiền rút tối thiểu một lần: 2000000.00, Số tiền tối đa: 1000000000.00";
    } else if (value > userInfo.fund) {
      errorMessage = "Tài khoản của bạn không đủ tiền";
    }

    if (errorMessage) {
      alert(errorMessage);
      return false;
    }

    const opts = {
      transaction_type: "cash",
      value: value,
      bank_name: bankName,
      bank_account_name: bankAccName,
      bank_account_number: bankAccNo,
    };
    authFetch("/api/transaction/create", {
      method: "post",
      body: JSON.stringify(opts),
    }).then((response) => {
      const statusCode = response.status;
      if (statusCode === 200) {
        alert(
          'Chúng tôi đã nhận được yêu cầu rút tiền của bạn và hiện đang được xác minh. Hãy kiểm tra "Trang chủ cá nhân" -> "Các báo cáo tài chính" để biết chi tiết.'
        );
        window.open(CHAT_URL, "_blank");
      } else if (statusCode === 403) {
        alert(
          "Bạn không thể rút tiền. Xin mời liên hệ với hỗ trợ để biết thêm chi tiết!"
        );
        window.open(CHAT_URL, "_blank");
      } else {
        alert("Không thể xử lý yêu cầu rút tiền. Xin mời thử lại!");
      }
    });
  };

  const handleBackClick = () => {
    window.location.href = "/user";
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    userInfo && (
      <Box className="user" height={`${wHeight}px`}>
        <Csrae />
        <Box height="90%" display="flex" flexDirection="column" overflow="auto">
          <UserInfo />
          <Box bgcolor="#e6e6e6" flexGrow={1}>
            <Box className="panel panel-info">
              <Box className="panel-heading">Hoạt động rút tiền</Box>
              <Box className="panel-body">
                <table className="table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <span className="row-title">Các loại:</span>
                      </td>
                      <td>
                        <select class="user_input" id="payType">
                          <option value="bank-card">Thẻ ngân hàng</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="row-title">Tên ngân hàng:</span>
                      </td>
                      <td>
                        <input
                          placeholder="Vui lòng nhập ngân hàng mở tài khoản"
                          type="text"
                          id="bankName"
                          class="user-input"
                          value={bankName}
                          onChange={handleBankNameChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="row-title">
                          Số tài khoản ngân hàng:
                        </span>
                      </td>
                      <td>
                        <input
                          placeholder="Vui lòng nhập số tài khoản của bạn"
                          type="text"
                          id="bankAccNo"
                          class="user-input"
                          value={bankAccNo}
                          onChange={handleBankAccNoChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="row-title">Họ và tên:</span>
                      </td>
                      <td>
                        <input
                          placeholder="Vui lòng nhập tên tài khoản của bạn"
                          type="text"
                          id="bankAccName"
                          class="user-input"
                          value={bankAccName}
                          onChange={handleBankAccNameChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="row-title">Số tiền rút:</span>
                      </td>
                      <td>
                        <input
                          placeholder="Số tiền tối thiểu của một lần rút tiền: 2000000.00"
                          type="text"
                          id="value"
                          class="user-input"
                          value={value}
                          onChange={handleValueChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handlePaymentClick}
                        >
                          Đăng ký rút tiền
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Box className="panel-note">
                  Thủ tục điều hành:
                  <br />
                  1、 Nhắc nhở quan trọng:
                  <br />
                  1、 Một lần rút tiền vượt quá 200,000,000, vui lòng điền thông
                  tin tài khoản ngân hàng chi tiết và xác nhận trước khi gửi
                  <br />
                  2、 Rút tiền cần đạt doanh số vốn tương ứng trước khi rút.
                  <br />
                  3、 Nếu lệnh rút tiền không được chấp thuận, vui lòng liên hệ
                  với bộ phận chăm sóc khách hàng trực tuyến để kịp thời xử lý
                  cho bạn
                  <br />
                  4、 Nền tảng này cấm tất cả các hoạt động kinh doanh bất hợp
                  pháp rửa tiền!
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="menu">
          <Box className="btn" onClick={handleBackClick}>
            <Box>trở lại trang chủ cá nhân</Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Cash;
