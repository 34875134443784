import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const SimpleTable = (props) => {
  const {
    columns,
    rows,
    emptyText,
    headFontSize = "1.4rem",
    bodyFontSize = "1rem",
  } = props;
  return (
    <Box width="100%" pt={2} {...props}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((header) => (
              <TableCell
                key={header.id}
                align="center"
                width={header.width}
                style={{ border: "1px solid #ddd", fontSize: headFontSize }}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align="center"
                style={{ fontSize: "1.4rem", background: "#e6e6e6" }}
              >
                <strong>{emptyText}</strong>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row) => (
              <TableRow>
                {Object.values(row).map((val) => (
                  <TableCell align="center" style={{ fontSize: bodyFontSize }}>
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SimpleTable;
