import React from "react";
import { Box } from "@mui/material";
import "../css/Game.css";
import SimpleTable from "./SimpleTable";

const columns = [
  {
    field: "1-time",
    label: <b style={{ color: "#825D51" }}>Thời gian</b>,
    width: "25%",
  },
  {
    field: "2-id",
    label: <b style={{ color: "#825D51" }}>Số kỳ</b>,
    width: "15%",
  },
  {
    field: "3-code",
    label: <b style={{ color: "#825D51" }}>Kết quả trúng số</b>,
    width: "35%",
  },
  { field: "4-result", label: "", width: "11%" },
  {
    field: "5-res1",
    label: <b style={{ color: "#825D51" }}>Tổng</b>,
    width: "7%",
  },
  { field: "6-res2", label: "", width: "7%" },
];

const GameHistory = ({ historyRows }) => (
  <Box width="80%" height="100vh" bgcolor="#AEBDDE">
    <Box bgcolor="#FFC657" color="#A67239" textAlign="center" padding={2}>
      Hồ sơ kết quả giao dịch
    </Box>
    <SimpleTable
      columns={columns}
      rows={historyRows}
      emptyText="Không có kết quả giao dịch"
      bgcolor="white"
      style={{ height: "85%", overflow: "auto" }}
      headFontSize="1.7rem"
      bodyFontSize="1.5rem"
    />
  </Box>
);

export default GameHistory;
