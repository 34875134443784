import React from "react";
import { Box } from "@mui/material";
import { AuthContext } from "../AuthContext";
import i01 from "../assets/images/menu/i01.png";
import i02 from "../assets/images/menu/i02.png";
import i03 from "../assets/images/menu/i03.png";
import i04 from "../assets/images/menu/i04.png";
import i041 from "../assets/images/menu/i041.png";
import i05 from "../assets/images/menu/i05.png";
import Csrae from "./Csrae";
import "../css/Home.css";

const MainMenu = (props) => {
  const { newMsgs } = React.useContext(AuthContext);
  const handleHomeClick = () => {
    window.location.href = "/home";
  };
  const handlePayClick = () => {
    window.location.href = "/pay";
  };
  const handleCashClick = () => {
    window.location.href = "/cash";
  };
  const handleCustomerServiceClick = () => {
    window.location.href = "/customer-service";
  };
  const handleUserClick = () => {
    window.location.href = "/user";
  };
  return (
    <Box className="menu" {...props} zIndex={999999}>
      <Csrae />
      <Box className="nav" onClick={handleHomeClick}>
        <Box>
          <img
            src={i01}
            style={{
              width: "40%",
              height: "20%",
            }}
            alt="i01"
          />
          <p>Trang chủ</p>
        </Box>
      </Box>
      <Box className="nav" onClick={handlePayClick}>
        <Box>
          <img
            src={i02}
            style={{
              width: "40%",
              height: "20%",
            }}
            alt="i02"
          />
          <p>Nạp tiền</p>
        </Box>
      </Box>
      <Box className="nav" onClick={handleCashClick}>
        <Box>
          <img
            src={i03}
            style={{
              width: "40%",
              height: "20%",
            }}
            alt="i03"
          />
          <p>Rút tiền</p>
        </Box>
      </Box>
      <Box className="nav" onClick={handleCustomerServiceClick}>
        <Box>
          <img
            src={newMsgs > 0 ? i041 : i04}
            style={{
              width: "40%",
              height: "20%",
            }}
            alt="i04"
          />
          <p>Dịch vụ khách hàng</p>
        </Box>
      </Box>
      <Box className="nav" onClick={handleUserClick}>
        <Box>
          <img
            src={i05}
            style={{
              width: "40%",
              height: "20%",
            }}
            alt="i05"
          />
          <p>Tôi</p>
        </Box>
      </Box>
    </Box>
  );
};

export default MainMenu;
