import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Box, Avatar } from "@mui/material";
import GetImage from "../../requests/GetImage";
import CustomerServiceIMG from "../../assets/images/avatar/CustomerService.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageGray: {
      position: "relative",
      marginBottom: "10px",
      padding: "10px",
      maxWidth: "100%",
      backgroundColor: "#6E6E6E",
      width: "fit-content",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      borderRadius: "10px",
    },
    messageOrange: {
      position: "relative",
      marginBottom: "10px",
      padding: "10px",
      maxWidth: "100%",
      backgroundColor: "#dd984e",
      width: "fit-content",
      textAlign: "left",
      color: "#FFFFFF",
      font: "400 .9em 'Open Sans', sans-serif",
      borderRadius: "10px",
      float: "right",
    },
    messageContent: {
      padding: 0,
      margin: 0,
      whiteSpace: "pre-line",
    },
    right: {
      float: "right",
      fontSize: ".75em",
      color: "#766060",
    },
  })
);

export const MessageLeft = (props) => {
  const { message, imgUrl, messageId, timestamp } = props;
  const [image, setImage] = React.useState();
  const { data } = GetImage({ messageId, enabled: Boolean(imgUrl) });
  useEffect(() => {
    if (data && data.data.image !== image) setImage(data.data.image);
  }, [data, image]);
  const classes = useStyles();
  return (
    <div className={classes.messageRow}>
      <Avatar
        style={{ width: 50, height: 50 }}
        variant="rounded"
        alt="Customer Service"
        src={CustomerServiceIMG}
      />
      <Box
        position="relative"
        style={{ font: "400 .8em 'Open Sans', sans-serif" }}
        color="white"
        pl={2}
      >
        <Box className={classes.right} style={{ float: "left" }} pb={1}>
          Customer Service {new Date(timestamp).toLocaleString()}
        </Box>
        <br />
        <div
          className={classes.messageGray}
          style={
            imgUrl &&
            messageId && {
              padding: 0,
              paddingBottom: 10,
              margin: 0,
              backgroundColor: "unset",
            }
          }
        >
          {message && <p className={classes.messageContent}>{message}</p>}
          {!message && image && (
            <img src={image} alt="msg-img" style={{ maxWidth: "80%" }} />
          )}
        </div>
      </Box>
    </div>
  );
};

export const MessageRight = (props) => {
  const { message, imgUrl, messageId, timestamp } = props;
  const [image, setImage] = React.useState();
  const { data } = GetImage({ messageId, enabled: Boolean(imgUrl) });
  useEffect(() => {
    if (data && data.data.image !== image) setImage(data.data.image);
  }, [data, image]);
  const classes = useStyles();
  return (
    <div className={classes.messageRowRight}>
      <Box
        position="relative"
        style={{ font: "400 .8em 'Open Sans', sans-serif" }}
        color="white"
      >
        <Box className={classes.right} pb={1}>
          {new Date(timestamp).toLocaleString()}
        </Box>
        <br />
        <div
          className={classes.messageOrange}
          style={
            imgUrl &&
            messageId && {
              padding: 0,
              paddingBottom: 10,
              margin: 0,
              backgroundColor: "unset",
            }
          }
        >
          {message && <p className={classes.messageContent}>{message}</p>}
          {!message && image && (
            <img
              src={image}
              alt="msg-img"
              style={{ maxWidth: "80%", float: "right" }}
            />
          )}
        </div>
      </Box>
    </div>
  );
};
