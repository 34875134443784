import React from "react";
import { Box } from "@mui/material";
// import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloseIcon from "@mui/icons-material/Close";

const Notify = ({ message, canClose }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    isOpen && (
      <Box
        position="absolute"
        color="white"
        top="50%"
        left="50%"
        minWidth={250}
        minHeight={140}
        bgcolor="rgba(0,0,0,0.6)"
        style={{ transform: "translate(-50%, -50%)" }}
        boxShadow="1px 1px 50px rgba(0,0,0,.3)"
        lineHeight="2"
        display="flex"
        zIndex={9999999}
      >
        {canClose && (
          <Box position="absolute" right="-5%" top="-5%" onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </Box>
        )}
        {message}
      </Box>
    )
  );
};

export default Notify;
