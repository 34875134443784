import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Avatar, Box } from "@mui/material";
import CustomerServiceIMG from "../../assets/images/avatar/CustomerService.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageGray: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#6E6E6E",
      width: "90%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #6E6E6E",
      borderRadius: "10px",
      bottom: "-10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #6E6E6E",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "15px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #6E6E6E",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "10px",
        left: "-20px",
      },
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#848484",
      color: "#FFFFFF",
      width: "60%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #848484",
      borderRadius: "10px",
      float: "right",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #848484",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "15px",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #848484",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "10px",
        right: "-20px",
      },
    },
    messageContent: {
      padding: 0,
      margin: 0,
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "-3px",
      right: "5px",
    },

    displayName: {
      marginLeft: "20px",
      marginBottom: "-20px",
    },

    right: {
      float: "right",
    },
  })
);

export const MessageLeft = (props) => {
  const {
    message,
    timestamp,
    displayName,
    photoURL,
    avatarStyle = { width: 100, height: 100 },
  } = props || {
    message: "No message",
    timestamp: "",
    displayName: "No name",
    photoURL: "0",
  };
  const avatarSrc = photoURL
    ? require(`../../assets/images/avatar/${photoURL}.png`)
    : CustomerServiceIMG;
  const classes = useStyles();
  return (
    <div className={classes.messageRow}>
      <Avatar
        style={avatarStyle}
        variant="rounded"
        alt={displayName}
        src={avatarSrc}
      />
      <Box
        position="relative"
        style={{ font: "400 .9em 'Open Sans', sans-serif" }}
        color="white"
        pl={2}
      >
        <Box className={classes.right} pb={1}>
          {displayName} {new Date(timestamp).toLocaleString()}
        </Box>
        <br />
        <div className={classes.messageGray}>
          <p className={classes.messageContent}>{message}</p>
        </div>
      </Box>
    </div>
  );
};

export const MessageRight = (props) => {
  const {
    message,
    timestamp,
    displayName,
    photoURL,
    avatarStyle = { width: 100, height: 100 },
  } = props || {
    message: "No message",
    timestamp: "",
    displayName: "No name",
  };
  const avatarSrc = photoURL
    ? require(`../../assets/images/avatar/${photoURL}.png`)
    : CustomerServiceIMG;
  const classes = useStyles();
  return (
    <div className={classes.messageRowRight}>
      <Box
        position="relative"
        style={{ font: "400 .9em 'Open Sans', sans-serif" }}
        color="white"
        pr={2}
      >
        <Box className={classes.right} pb={1}>
          {new Date(timestamp).toLocaleString()} {displayName}
        </Box>
        <br />
        <div className={classes.messageOrange}>
          <p className={classes.messageContent}>{message}</p>
        </div>
      </Box>
      <Avatar
        style={avatarStyle}
        variant="rounded"
        alt={displayName}
        src={avatarSrc}
      />
    </div>
  );
};
