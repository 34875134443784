import axiosAPI from "./axiosSetup";
import { useQuery } from "react-query";

const GetLastMessage = (props) => {
  const { enabled, interval = 5000 } = props;
  const url = "api/ticket/get_last_message_id";
  return useQuery(["get-last-message"], () => axiosAPI.get(url), {
    refetchInterval: interval,
    notifyOnChangeProps: ["data", "error"],
    enabled,
  });
};

export default GetLastMessage;
