import React, { useContext } from "react";
import { AuthContext } from "../AuthContext";
import GetLastMessage from "../requests/GetLastMessage";
import { playNotificationSound } from "../utils";
import Transition from "./Transition";

const Csrae = ({ enabled = true }) => {
  const { userInfo, useAuth, newMsgs, setNewMsgs, unSeenMsg, setUnSeenMsg } =
    useContext(AuthContext);
  const [logged] = useAuth();
  const { lastSeenMessageId } = userInfo || {};
  const { data } = GetLastMessage({
    enabled: Boolean(logged) && Boolean(userInfo) && enabled,
  });
  React.useEffect(() => {
    if ((data?.data?.lastMessageId || 0) - lastSeenMessageId > newMsgs) {
      setUnSeenMsg(true);
      setNewMsgs((data?.data?.lastMessageId || 0) - lastSeenMessageId);
      playNotificationSound();
    }
  }, [data, lastSeenMessageId, setNewMsgs, newMsgs, setUnSeenMsg]);
  return (
    unSeenMsg && (
      <Transition message="Có tin nhắn mới" setUnSeenMsg={setUnSeenMsg} />
    )
  );
};

export default Csrae;
