import React, { useEffect } from "react";
import { MessageLeft, MessageRight } from "./tMessage";

const TicketMessage = (props) => {
  const { messageData = [], scrollToBottom, messagesEndRef, userInfo } = props;

  useEffect(() => {
    scrollToBottom();
  }, [messageData, scrollToBottom]);
  return (
    <>
      {messageData.map((value) => {
        const {
          user_id: userId,
          id: messageId,
          create_date: createDate,
          message,
          image_url: imgUrl,
        } = value;
        if (userId === userInfo.id) {
          return (
            <div>
              <MessageRight
                message={message}
                timestamp={createDate}
                imgUrl={imgUrl}
                messageId={messageId}
                scrollToBottom={scrollToBottom}
              />
            </div>
          );
        }

        return (
          <div style={{ padding: "0x 10px" }}>
            <MessageLeft
              message={message}
              timestamp={createDate}
              imgUrl={imgUrl}
              messageId={messageId}
              scrollToBottom={scrollToBottom}
              // userInfo={userInfo}
            />
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </>
  );
};
export default TicketMessage;
