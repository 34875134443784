import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AuthContext } from "../AuthContext";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import "../css/Login.css";

const CustomTab = (props) => (
  <Tab
    {...props}
    style={{ fontSize: 20, fontWeight: "bold" }}
    sx={{
      "&.MuiTab-root": {
        color: "#6a6f8c",
      },
      "&.Mui-selected": {
        color: "#fff",
      },
    }}
  />
);

const Login = () => {
  const { useAuth } = React.useContext(AuthContext);
  const [logged] = useAuth();
  React.useEffect(() => {
    if (logged) {
      window.location.href = "/home";
    }
  }, [logged]);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [reference, setReference] = useState(params.get("ref"));
  const [redirect, setRedirect] = useState(params.get("redirect"));
  const [value, setValue] = React.useState("1");
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    if (redirect) {
      setValue(redirect);
      setRedirect(null);
    }
  }, [redirect]);
  return (
    !logged && (
      <Box className="login">
        <TabContext value={value}>
          <TabList value={value} onChange={handleChange} aria-label="login">
            <CustomTab label="ĐĂNG NHẬP" value="1" />
            <CustomTab label="ĐĂNG KÝ" value="2" />
          </TabList>
          <TabPanel value="1">
            <LoginForm />
          </TabPanel>
          <TabPanel value="2">
            <SignupForm reference={reference} setReference={setReference} />
          </TabPanel>
        </TabContext>
      </Box>
    )
  );
};

export default Login;
