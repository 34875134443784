import axiosAPI from "./axiosSetup";
import { useQuery } from "react-query";

const GetMessage = (props) => {
  const { messageId } = props;
  const url = "api/message?message_id=".concat(messageId);
  return useQuery(["get-message", messageId], () => axiosAPI.get(url));
};

export default GetMessage;
