import React, { useContext, useState } from "react";
import { Button } from "@mui/material";
import { AuthContext } from "../AuthContext";
import { ALPHANUMERIC_PATTERN } from "../constants.js";

const LoginForm = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmitClick = (e) => {
    e.preventDefault();
    let errorMessage = "";
    if (username.length < 3 || username.length > 16) {
      errorMessage = "Tạo tên người dùng ít nhất 3 - 16 ký tự";
    } else if (!ALPHANUMERIC_PATTERN.test(username)) {
      errorMessage = "Tên người dùng có thể chứa số tiếng Anh";
    } else if (password.length < 3 || password.length > 16) {
      errorMessage = "Tạo mật khẩu chứa ít nhất 3-16 ký tự";
    } else if (!ALPHANUMERIC_PATTERN.test(password)) {
      errorMessage = "Mật khẩu có thể chứa chữ số tiếng Anh";
    }

    if (errorMessage) {
      alert(errorMessage);
      return;
    }
    let opts = {
      username: username,
      password: password,
    };
    fetch("/api/login", {
      method: "post",
      body: JSON.stringify(opts),
    }).then((r) => {
      if (r.status === 200) {
        r.json()
          .then((token) => {
            if (token.access_token) {
              login(token);
              window.location.href = "/home";
            }
          })
          .catch((error) => {
            alert("Không thể đăng nhập. Vui lòng thử lại!");
          });
      } else {
        alert("Tài khoản và mật khẩu chưa đúng. Vui lòng thử lại!");
      }
    });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div>
      <form action="#" autoComplete={false}>
        <div className="Login-div-input">
          <label className="Login-label">TÊN TÀI KHOẢN</label>
          <input
            type="text"
            onChange={handleUsernameChange}
            value={username}
            className="Login-input"
          />
        </div>
        <div className="Login-div-input">
          <label className="Login-label">MẬT KHẨU</label>
          <input
            type="password"
            onChange={handlePasswordChange}
            value={password}
            className="Login-input"
          />
        </div>
        <Button
          variant="contained"
          size="medium"
          className="Login-btn"
          onClick={onSubmitClick}
          type="submit"
        >
          Đăng nhập
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
