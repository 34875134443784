import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth, logout } from "../auth";
import UserInfo from "./UserInfo";
import Csrae from "./Csrae";
import "../css/User.css";

const User = () => {
  const [logged] = useAuth();
  useEffect(() => {
    if (!logged) window.location.href = "/";
  }, [logged]);
  const userInfo = {
    username: "test",
    avatar: 0,
    fund: 1000000,
    profit: 100000,
  };
  const handleSettingClick = () => {
    window.location.href = "/account";
  };
  const handleOrderClick = () => {
    window.location.href = "/order-info";
  };
  const handleLogClick = () => {
    window.location.href = "/pay-log";
  };
  const handleLogoutClick = () => {
    logout();
    window.location.href = "/login";
  };
  const handleBackClick = () => {
    window.location.href = "/home";
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    userInfo && (
      <Box className="user" height={`${wHeight}px`}>
        <Csrae />
        <Box height="90%" display="flex" flexDirection="column">
          <UserInfo />
          <Button
            className="entry-btn"
            startIcon={<PersonOutlineIcon />}
            onClick={handleSettingClick}
          >
            Thiết lập tài khoản
          </Button>
          <Button
            className="entry-btn"
            startIcon={<LocalAtmIcon />}
            onClick={handleOrderClick}
          >
            Thông tin giao dịch
          </Button>
          <Button
            className="entry-btn"
            startIcon={<AssessmentOutlinedIcon />}
            onClick={handleLogClick}
          >
            Các báo cáo tài chính
          </Button>
          <Button
            className="entry-btn"
            startIcon={<LogoutIcon />}
            onClick={handleLogoutClick}
          >
            Đăng xuất khỏi tài khoản
          </Button>
        </Box>
        <Box className="btn" onClick={handleBackClick}>
          trở lại trang chủ
        </Box>
      </Box>
    )
  );
};

export default User;
