import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { AuthContext } from "../AuthContext";

const UserInfo = () => {
  const { userInfo } = useContext(AuthContext);
  const { username, avatar, fund, profit } = userInfo || {};
  return (
    userInfo && (
      <Box bgcolor="#1d2025" maxHeight="35%">
        <Box className="user-info">
          <Box className="user-photo">
            <img
              src={require(`../assets/images/avatar/${avatar}.png`)}
              alt="user"
            />
          </Box>
          <Box className="user-text">
            <Typography fontSize="1.3rem">
              <strong>{username}</strong>
              <br />
              Chào mừng bạn đến【Citibank】Phòng tiếp đón
            </Typography>
          </Box>
        </Box>
        <Box className="fund-info">
          <Box className="fund-item" textAlign="left">
            <span className="val">{fund}</span>
            <br />
            <span className="key">Ví của tôi</span>
          </Box>
          <Box className="fund-item" textAlign="right">
            <span className="val">{profit || "0"}</span>
            <br />
            <span className="key">Lợi nhuận</span>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default UserInfo;
