import React, { createContext, useEffect, useState } from "react";
import { createAuthProvider } from "react-token-auth";
import GetLastMessage from "./requests/GetLastMessage";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [useAuth, authFetch, login, logout] = createAuthProvider({
    accessTokenKey: "access_token",
  });
  const [logged] = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [unSeenMsg, setUnSeenMsg] = useState(false);
  const [newMsgs, setNewMsgs] = useState(0);

  const reLogin = () => {
    logout();
    window.location.href = "/login";
  };

  useEffect(() => {
    if (logged) {
      authFetch("/api/user/info").then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setUserInfo(data);
          });
        } else {
          alert("Không lấy được thông tin user. Xin mời đăng nhập lại!");
          reLogin();
        }
      });
    }
  }, [logged]);

  return (
    <AuthContext.Provider
      value={{
        useAuth,
        authFetch,
        login,
        reLogin,
        userInfo,
        unSeenMsg,
        newMsgs,
        setNewMsgs,
        setUnSeenMsg,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
