import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import "../css/Welcome.css";

const Welcome = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const ref = params.get("ref");
  return (
    <Box textAlign="center" className="welcome">
      <img
        src={require("../assets/images/Welcome.jpg")}
        style={{ display: "inline", width: "50%", padding: 30 }}
        alt=""
      />
      <Typography fontSize={10}>
        Citibank, N. A. (N. A. stands for "National Association") is the primary
        U.S. banking subsidiary of financial services multinational
        Citigroup.[1] Citibank was founded in 1812 as the City Bank of New York,
        and later became First National City Bank of New York.[2] The bank has
        2,649 branches in 19 countries, including 723 branches in the United
        States and 1,494 branches in Mexico operated by its subsidiary
        Banamex.[citation needed] The U.S. branches are concentrated in six
        metropolitan areas: New York, Chicago, Los Angeles, San Francisco,
        Washington, D.C., and Miami.[3]
        <br />
        It was founded as City Bank of New York and became National City Bank of
        New York.
        <br />
        Citibank is the 3rd largest bank in the United States in terms of
        assets.[4]The City Bank of NewYork was founded on June 16, 1812.[5] The
        first president of the City Bankwas thestatesmanand retired Colonel,
        Samuel Osgood. After Osgood's death inAugust1813, William Few became
        President of the bank, staying until 1817, followed by Peter
        Stagg(1817–1825), Thomas Smith (1825–1827), Isaac Wright (1827–1832),
        and Thomas Bloodgood (1832–1843). Moses Taylor assumed ownership and
        management of the bank in 1837. During Taylor's ascendancy, the bank
        functioned largely as a treasury and finance center for Taylor's own
        extensive business empire.[6] Later presidents of the bank included
        Gorham Worth (1843–1856), Moses Taylor himself (1856–1882), Taylor's
        son-in-law Patrick Pyne, and James Stillman (1891–1909).
      </Typography>
      <Button
        variant="outlined"
        style={{ margin: "20px 0", width: 200, borderRadius: 24 }}
        onClick={() => {
          const loginPath = ref ? `/login?ref=${ref}` : "/login";
          window.location.href = loginPath;
        }}
      >
        Tiếp tục
      </Button>
    </Box>
  );
};

export default Welcome;
