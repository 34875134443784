import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { AuthContext } from "../AuthContext";
import Room1 from "../assets/images/Room1.jpg";
import Room2 from "../assets/images/Room2.jpg";
import Room3 from "../assets/images/Room3.jpg";
import MainMenu from "./MainMenu.js";
import Notify from "./Notify";
import "../css/Home.css";

const Room = () => {
  const { useAuth, userInfo } = useContext(AuthContext);
  const [logged] = useAuth();
  useEffect(() => {
    if (!logged) window.location.href = "/";
  }, [logged]);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const roomType = params.get("type");
  useEffect(() => {
    if (!roomType) window.location.href = "/room?type=normal";
  }, [roomType]);

  const [showNotify, setShowNotify] = React.useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (showNotify) setShowNotify(0);
    }, 10_000);

    return () => clearTimeout(timer);
  }, [showNotify]);

  const handleRoomClick = (roomNo) => {
    if (
      (roomNo === 2 && userInfo?.fund < 150_000_000) ||
      (roomNo === 3 && userInfo?.fund < 2_000_000_000)
    ) {
      setShowNotify(roomNo);
      return;
    }
    window.location.href = `/game?type=${roomType}&room=${roomNo}`;
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    <Box className="home" height={`${wHeight}px`}>
      {Boolean(showNotify) && (
        <Notify
          message={
            <p>
              Bạn không thể vào phòng VIP {showNotify}.
              <br />
              Số tiền hiện tại:{userInfo?.fund} Có thể nhập:Phòng VIP{" "}
              {userInfo?.fund < 150_000_000 ? "1" : "2"}
              <br />
              Điều kiện vào phòng:
              <br />
              Phòng VIP 1: Số tiền hiện tại ≧ 0.00
              <br />
              Phòng VIP 2: Số tiền hiện tại ≧ 150000000.00
              <br />
              Phòng VIP 3: Số tiền hiện tại ≧ 2000000000.00
            </p>
          }
        />
      )}
      <Box sx={{ height: "90%", cursor: "pointer", overflow: "auto" }}>
        <img
          src={Room1}
          style={{
            width: "100%",
            height: "35%",
          }}
          alt="room1"
          onClick={() => handleRoomClick(1)}
        />
        <img
          src={Room2}
          style={{
            width: "100%",
            height: "35%",
          }}
          alt="room2"
          onClick={() => handleRoomClick(2)}
        />
        <img
          src={Room3}
          style={{
            width: "100%",
            height: "35%",
          }}
          alt="room3"
          onClick={() => handleRoomClick(3)}
        />
      </Box>
      <MainMenu />
    </Box>
  );
};

export default Room;
