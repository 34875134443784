import React from "react";
import { Snackbar, Slide } from "@mui/material";

const SlideTransition = (props) => <Slide {...props} direction="up" />;

const Transition = ({ message, setUnSeenMsg }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const handleClose = () => {
    setIsOpen(false);
    setUnSeenMsg(false);
  };

  return (
    isOpen && (
      <Snackbar
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        message={message}
        key={SlideTransition.name}
        autoHideDuration={1500}
      />
    )
  );
};

export default Transition;
