import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { CHAT_URL } from "../constants";
import { AuthContext } from "../AuthContext";
import UserInfo from "./UserInfo";
import Csrae from "./Csrae";
import "../css/User.css";

const Pay = () => {
  const { useAuth, authFetch, userInfo } = useContext(AuthContext);
  const [logged] = useAuth();
  const [value, setValue] = useState("");
  useEffect(() => {
    if (!logged) {
      window.location.href = "/login";
    }
  }, [logged]);
  const handleValueChange = (e) => {
    setValue(e.target.value);
  };
  const handlePaymentClick = (e) => {
    let errorMessage = "";
    if (!value || isNaN(value)) {
      errorMessage = "Số tiền không hợp lệ";
    } else if (value < 2000000) {
      errorMessage = "Số tiền tối thiểu của một lần nạp tiền: 2000000.00";
    }

    if (errorMessage) {
      alert(errorMessage);
      return false;
    }

    const opts = {
      transaction_type: "pay",
      value: value,
    };
    authFetch("/api/transaction/create", {
      method: "post",
      body: JSON.stringify(opts),
    }).then((response) => {
      if (response.status === 200) {
        alert(
          'Chúng tôi đã nhận được yêu cầu nạp tiền của bạn và hiện đang được xác minh.\nHãy kiểm tra "Trang chủ cá nhân" -> "Các báo cáo tài chính" để biết chi tiết.'
        );
        window.open(CHAT_URL, "_blank");
      } else {
        alert("Không thể gửi yêu cầu nạp tiền!");
      }
    });
  };

  const handleBackClick = () => {
    window.location.href = "/user";
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    userInfo && (
      <Box className="user" height={`${wHeight}px`}>
        <Csrae />
        <Box height="90%" display="flex" flexDirection="column" overflow="auto">
          <UserInfo />
          <Box bgcolor="#e6e6e6" flexGrow={1}>
            <Box className="panel panel-info">
              <Box className="panel-heading">Hoạt động nạp tiền</Box>
              <Box className="panel-body">
                <table className="table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <span className="row-title">Các loại:</span>
                      </td>
                      <td>
                        <select class="user_input" id="payType">
                          <option value="bank-card">Thẻ ngân hàng</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="row-title">Số tiền nạp:</span>
                      </td>
                      <td>
                        <input
                          placeholder="Số tiền tối thiểu của một lần nạp tiền: 2000000.00"
                          type="text"
                          id="value"
                          class="user-input"
                          value={value}
                          onChange={handleValueChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handlePaymentClick}
                        >
                          Nạp tiền
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Box className="panel-note">
                  Thủ tục điều hành:
                  <br />
                  1、{" "}
                  <span style={{ color: "#3399ff", cursor: "pointer" }}>
                    Dịch vụ khách hàng trực tuyến (nhấp vào để tiếp tục){" "}
                  </span>
                  ,Nhận mã QR nạp tiền hoặc tài khoản ngân hàng.
                  <br />
                  2、 Sau khi người dùng tự nạp tiền, hãy liên hệ với bộ phận
                  chăm sóc khách hàng, cung cấp số sê-ri nạp tiền và bộ phận
                  chăm sóc khách hàng sẽ kiểm tra.
                  <br />
                  3、 Sau khi bộ phận chăm sóc khách hàng kiểm tra không có lỗi,
                  tiền sẽ được chuyển vào tài khoản của bạn.
                  <br />
                  4、Người dùng có thể xem "Trang chủ cá nhân" -&gt; "Các báo
                  cáo tài chính" để biết chi tiết.
                  <br />
                  5、 Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ
                  phận chăm sóc khách hàng trực tuyến để thương lượng và giải
                  quyết.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="menu">
          <Box className="btn" onClick={handleBackClick}>
            <Box>trở lại trang chủ cá nhân</Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Pay;
