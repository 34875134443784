import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import Welcome from "./pages/Welcome";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Pay from "./pages/Pay";
import Cash from "./pages/Cash";
import User from "./pages/User";
import Account from "./pages/Account";
import OrderHistory from "./pages/OrderHistory";
import TransactionHistory from "./pages/TransactionHistory";
import Room from "./pages/Room";
import Game from "./pages/Game";
import CustomerService from "./pages/CustomerService";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 0,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/cash" element={<Cash />} />
          <Route path="/user" element={<User />} />
          <Route path="/account" element={<Account />} />
          <Route path="/customer-service" element={<CustomerService />} />
          <Route path="/order-info" element={<OrderHistory />} />
          <Route path="/pay-log" element={<TransactionHistory />} />
          <Route path="/room" element={<Room />} />
          <Route path="/game" element={<Game />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}
