import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";

import UserInfo from "./UserInfo";
import SimpleTable from "./SimpleTable";
import { AuthContext } from "../AuthContext";
import {
  getTodayRange,
  getYesterdayRange,
  get30DaysRange,
} from "../utils/TimeRange";
import Csrae from "./Csrae";
import "../css/User.css";

const columns = [
  { field: "orderId", label: "Giao dịch", width: "20%" },
  { field: "gameId", label: "Số kỳ", width: "20%" },
  { field: "orderContent", label: "Nội dung", width: "35%" },
  { field: "profit", label: "Lợi nhuận", width: "25%" },
];

const OrderHistory = () => {
  const { useAuth, authFetch, reLogin, userInfo } = useContext(AuthContext);
  const [logged] = useAuth();
  const { s, e } = getTodayRange();
  const [timeRange, setTimeRange] = useState("");
  const [start, setStart] = useState(s);
  const [end, setEnd] = useState(e);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (!logged) {
      window.location.href = "/login";
    }
    if (!start || !end || start === 0 || end === 0 || start > end) return;
    const url = `/api/user/orders?start=${start}&end=${end}&request_type=1`;
    authFetch(url).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setRows(data?.data);
        });
      } else {
        alert("Không lấy được thông tin giao dịch. Xin mời đăng nhập lại!");
        reLogin();
      }
    });
  }, [logged, start, end]);
  const handleTodayClick = () => {
    if (timeRange === "today") return;
    const { start, end } = getTodayRange();
    setTimeRange("today");
    setStart(start);
    setEnd(end);
  };
  const handleYesterdayClick = () => {
    if (timeRange === "yesterday") return;
    const { start, end } = getYesterdayRange();
    setTimeRange("yesterday");
    setStart(start);
    setEnd(end);
  };
  const handleMonthClick = () => {
    if (timeRange === "30days") return;
    const { start, end } = get30DaysRange();
    setTimeRange("30days");
    setStart(start);
    setEnd(end);
  };
  const handleBackClick = () => {
    window.location.href = "/user";
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    userInfo && (
      <Box className="user" height={`${wHeight}px`}>
        <Csrae />
        <Box height="90%" display="flex" flexDirection="column">
          <UserInfo />
          <Grid container spacing={1} marginLeft="-4px" marginTop={2}>
            <Grid item xs={4}>
              <Button
                onClick={handleTodayClick}
                variant="contained"
                style={{ width: "100%", height: 40 }}
              >
                Hôm nay
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleYesterdayClick}
                variant="contained"
                style={{ width: "100%", height: 40 }}
              >
                Hôm qua
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleMonthClick}
                variant="contained"
                style={{ width: "100%", height: 40 }}
              >
                30 ngày
              </Button>
            </Grid>
          </Grid>
          <SimpleTable
            columns={columns}
            rows={rows}
            emptyText="Không có giao dịch"
            style={{ height: "85%", overflow: "auto" }}
          />
        </Box>
        <Box className="btn" onClick={handleBackClick}>
          trở lại trang chủ cá nhân
        </Box>
      </Box>
    )
  );
};

export default OrderHistory;
