import React from "react";
import { MessageLeft, MessageRight } from "./message";

const HistoryMessage = (props) => {
  const { messageData = [] } = props;
  return (
    <>
      {messageData.map((value) => {
        const {
          user_id: userId,
          create_date: createDate,
          message,
          username = "No Name",
          avatar = -1,
        } = value;
        if (userId === 1) {
          return (
            <div style={{ padding: "20px" }}>
              <MessageRight
                message={message}
                timestamp={createDate}
                displayName={username}
                photoURL={avatar}
              />
            </div>
          );
        }

        return (
          <div style={{ padding: "20px" }}>
            <MessageLeft
              message={message}
              timestamp={createDate}
              displayName={username}
              photoURL={avatar}
            />
          </div>
        );
      })}
    </>
  );
};
export default HistoryMessage;
