export const getTodayRange = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const end = now;
  return { start: start.getTime(), end: end.getTime() };
};

export const getYesterdayRange = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
  const end = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1,
    23,
    59,
    59,
    999
  );
  return { start: start.getTime(), end: end.getTime() };
};

export const get30DaysRange = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29);
  const end = now;
  return { start: start.getTime(), end: end.getTime() };
};
