import axiosAPI from "./axiosSetup";
import { useQuery } from "react-query";

const GetImage = (props) => {
  const { messageId, enabled } = props;
  const url = "api/ticket/get_image?message_id=".concat(messageId);
  return useQuery(["get-image", messageId], () => axiosAPI.get(url), {
    notifyOnChangeProps: ["data", "error"],
    enabled,
  });
};

export default GetImage;
