import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { AuthContext } from "../AuthContext";
import headerImg from "../assets/images/HomeHeader.jpg";
import fakeImg from "../assets/images/001.jpg";
import investorImg from "../assets/images/investor.png";
import followerImg from "../assets/images/follower.png";
import gameNormalImg from "../assets/images/GameNormal.jpg";
import gameVipImg from "../assets/images/GameVip.jpg";
import MainMenu from "./MainMenu.js";
import Notify from "./Notify";
import "../css/Home.css";

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Home = () => {
  const { useAuth } = useContext(AuthContext);
  const [logged] = useAuth();
  useEffect(() => {
    if (!logged) window.location.href = "/login";
  }, [logged]);

  const [numberInvestor, setNumberInvestor] = useState(
    3798000 + getRandomNumber(-100, 150)
  );
  const [numberFollower, setNumberFollower] = useState(
    18000 + getRandomNumber(-10, 15)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setNumberInvestor(numberInvestor + getRandomNumber(-100, 150));
      setNumberFollower(numberFollower + getRandomNumber(-10, 15));
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  const handleGameNormalClick = () => {
    window.location.href = "/room?type=normal";
  };
  const handleGameVipClick = () => {
    window.location.href = "/room?type=vip";
  };

  const wHeight = window.innerHeight || document.documentElement.clientHeight;
  return (
    logged && (
      <Box className="home" overflow="hidden" height={`${wHeight}px`}>
        <Notify
          message={
            <p>
              Thời gian nộp tiền┅┅┅┅┅┅┅11:00 - 00:00
              <br />
              Thời gian rút tiền┅┅┅┅┅┅┅12:00 - 00:00
              <br />
              Thời gian phục vụ ┅┅┅┅┅┅┅10:00 - 00:00
              <br />
              ┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅┅
              <br />
              KÍNH GỬI CÁC NHÀ ĐẦU TƯ
              <br />
              TRƯỚC KHI NỘP TIỀN VUI LÒNG VÀO DỊCH VỤ CHĂM SÓC KHÁCH HÀNG XIN SỐ
              TÀI KHOẢN NGÂN HÀNG
            </p>
          }
          canClose
        />
        <Box height="90%" overflow="auto">
          <Box width="100%" height="45%">
            <img
              src={headerImg}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 640,
              }}
              alt="home-header"
            />
          </Box>
          <Box
            height={22}
            padding="0px 2px"
            borderTop="1px solid #F3F1FD"
            borderBottom="1px solid #F3F1FD"
            overflow="hidden"
          >
            <Typography paddingLeft={1}>Sự thông báo</Typography>
            <marquee direction="left" align="bottom" scrollamount="5">
              Kính gửi các thành viên cũ và mới: Để đảm bảo an toàn cho tiền của
              bạn, vui lòng tham khảo dịch vụ khách hàng trực tuyến để biết kênh
              nạp tiền mới nhất trước mỗi lần nạp tiền. Nếu chuyển khoản và nạp
              tiền của bạn không phải là kênh mới nhất được cung cấp bởi dịch vụ
              khách hàng, nền tảng sẽ không chịu trách nhiệm về bất kỳ tổn thất
              nào về số tiền. Cảm ơn bạn! !
            </marquee>
          </Box>
          <Box className="info">
            <img
              src={fakeImg}
              style={{
                width: 48,
                height: 56,
                float: "right",
              }}
              alt=""
            />
            <Box className="info-div">
              <img src={investorImg} alt="investor" />
              <Typography>Nhà đầu tư: {numberInvestor}</Typography>
            </Box>
            <Box className="info-div">
              <img src={followerImg} alt="follower" />
              <Typography>Người theo dõi: {numberFollower}</Typography>
            </Box>
          </Box>
          <Box className="games cursor">
            <img
              src={gameNormalImg}
              onClick={handleGameNormalClick}
              alt="game-normal"
            />
            <img src={gameVipImg} onClick={handleGameVipClick} alt="game-vip" />
          </Box>
        </Box>
        <MainMenu />
      </Box>
    )
  );
};

export default Home;
